/* CSS for MilestoneReferral Component */

.main-wrapper {
  display: grid;
  place-items: center;
}

.steps-wrapper {
  width: 100%;
}

.steps-wrapper .steps {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.steps-wrapper .steps .step {
  padding: 0 15px;
}

.steps-wrapper .steps .step .rnumber {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #dedede;
  border-radius: 50%;
  color: #878787;
  background: white;
  font-size: 24px;
  font-weight: 600;
  transition: 200ms ease;
  transition-delay: 0ms;
}

.steps-wrapper .steps .step.active .rnumber {
  color: #4052dbff;
  border-color: #4052dbff;
  transition-delay: 100ms;
}

.steps-wrapper .steps .progress-bar {
  position: absolute;
  width: 100%;
  height: 4px;
  background: #dedede;
  z-index: -1;
  top: 25px;
}

.steps-wrapper .steps .progress-bar .progress {
  position: absolute;
  height: 100%;
  background: #4052dbff;
  transition: 300ms ease;
}

.steps-wrapper .buttons {
  margin-block-start: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.steps-wrapper .buttons .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  background: #4052dbff;
  color: #fff;
  cursor: pointer;
  transition: 200ms linear;
}

.steps-wrapper .buttons .btn:active {
  scale: 0.9;
}

.steps-wrapper .buttons .btn:hover:not(.steps-wrapper .buttons .btn:disabled) {
  background: #4052dbff;
}

.steps-wrapper .buttons .btn:disabled {
  cursor: not-allowed;
  background: #878787;
  pointer-events: none;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rewards-img {
  border: 1px solid #eae9f6;
  width: 165px;
  height: 165px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
}
