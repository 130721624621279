body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    /* background-color: #F5F7F9FF; */
    overflow-x: hidden;
}

.show-more-less-text-color{
    color:blue;
}

p {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #6B6D72FF;
}

.google-review-font{
    font-size: 7px !important;
    color: grey !important;
    text-align: left !important;
}

a {
    text-decoration: none;
}

a:hover {
    color: #19237f !important;
}

.rubik {
    font-family: "Rubik", sans-serif;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.text-green {
    color: #44BA95FF;
}

.text-orange {
    color: #DB762AFF
}

.text-yellow {
    color: #DDB70EFF;
}

.bg-gray {
    background-color: #F5F7F9FF;
}

/* Navbar Start */

.nav-width {
    width: calc(95% - 80px);
}

body.offcanvas-active {
    overflow: hidden;
}

.offcanvas-header {
    display: none;
}

.screen-darken {
    height: 100%;
    width: 0%;
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(34, 34, 34, 0.6);
    transition: opacity .2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active {
    z-index: 10;
    transition: opacity .3s ease, width 0s;
    opacity: 1;
    width: 100%;
    visibility: visible;
}

/* ============ mobile view ============ */
@media all and (max-width: 991px) {
    .offcanvas-header {
        display: block;
    }

    .mobile-offcanvas {
        visibility: hidden;
        transform: translateX(-100%);
        border-radius: 0;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 80%;
        z-index: 1200;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: visibility .8s ease-in-out, transform .8s ease-in-out;
        background-color: #ffffff;
    }

    .mobile-offcanvas.show {
        visibility: visible;
        transform: translateX(0);
    }

    .mobile-offcanvas .container,
    .mobile-offcanvas .container-fluid {
        display: block;
    }

    .mobile-menu button {
        border: 0;
        background-color: transparent;
    }

    .mobile-menu {
        -webkit-box-shadow: 0 3px 15px 0 rgb(0 0 0 / 6%);
        -moz-box-shadow: 0 3px 15px 0 rgb(0 0 0 / 6%);
        box-shadow: 0 3px 15px 0 rgb(0 0 0 / 6%);
        /* background-color: #fff; */
        padding: 15px;
        position: sticky;
        -webkit-position: sticky;
        z-index: 1;
    }

    .navbar-brand img {
        width: 100px !important;
    }

    .mobile-login {
        position: absolute;
        right: 60px;
        top: 24px;
    }

    .login-btn {
        font-size: 12px !important;
        border-radius: 8px !important;
        padding: 5px 15px !important;
    }


    .hamburger img {
        width: 20px;
    }

    .navbar-nav {
        margin-top: 40px;
    }

    .offcanvas-header .btn-close {
        margin-top: 10px;
        margin-right: 10px;
    }




}

/* ============ mobile view .end// ============ */



.navbar-brand img {
    width: 150px;
}

.navbar-nav a {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    color: #171A1FFF !important;
    margin-right: 30px;
}

.navbar-nav a:hover {
    color: #db762a !important;
}

.login-btn {
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4052DBFF !important;
    opacity: 1;
    border-radius: 8px;
    border: 1px solid #4052DBFF !important;
    padding: 8px 20px;
}

.login-btn:hover {
    background-color: #4052DBFF;
    color: #ffffff !important;
}

.fixed-top {
    background-color: #F5F7F9FF;
    -webkit-box-shadow: 0 3px 15px 0 rgb(0 0 0 / 6%);
    -moz-box-shadow: 0 3px 15px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 6%);
}

.fixed-top .navbar-brand,
.fixed-top .navbar-nav,
.navbar.fixed-top,
.navbar-brand,
.navbar,
.navbar-nav {
    transition: 0.8s;
    -webkit-transition: 0.8s;
}

/* Navbar End */



.max-width1 {
    max-width: 1450px !important;
    margin: 0 auto !important;
    width: 100% !important;
}



.home-banner {
    background-image: url(../images/home/banner-img.jpg);
    height: 80vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    border-radius: 20px;
}

.home-banner h1 {
    font-size: 40px;
    margin-bottom: 20px;
}

.h1-small {
    font-size: 30px;
}

.banner-txt {
    font-size: 14px;
    font-weight: 500;
    writing-mode: vertical-rl;
    position: absolute;
    left: 20px;
}


.banner-txt::before {
    content: '';
    position: absolute;
    /* right: 0; */
    width: 2px;
    height: 2px;
    background-color: #ffffff;
    bottom: -60%;
    opacity: 0.6;
    height: 50%;
    left: 10px;
}


.banner-txt::after {
    content: '';
    position: absolute;
    /* right: 0; */
    width: 2px;
    height: 2px;
    background-color: #ffffff;
    top: -60%;
    opacity: 0.6;
    height: 50%;
    left: 10px;
}

.banner-form {
    /* display: flex; */
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 8px 17px #171a1f, 0px 0px 2px #171a1f;
    padding: 10px 15px;
    margin-top: 40px;
    width: 700px;
    max-width: 100%;
}

.banner-form input {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    padding-left: 0;
    border-left: 0;
}

.tf-v1-close-icon {
    display: none !important;
}

.banner-form .input-group {
    background: #FFFFFFFF;
    border-radius: 8px;
    border: 1px solid #BDC1CAFF;
    height: 52px;

}

::placeholder {
    color: #BDC1CAFF !important;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #BDC1CAFF !important;
}

.banner-form .input-group-text {
    background-color: transparent;
}

.banner-form .btn {
    background: #4052DBFF;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 60px;
    height: 50px;
    width: 100%;

}

.banner-form .btn:hover {
    background: transparent;
    border: 1px solid #4052DBFF;
    color: #4052DBFF;
}

.form-control:focus {
    background-color: transparent;
    border: 0;
    box-shadow: none;
}

.social-media-icon {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
}


.social-media-icon svg {
    fill: #ffffff;
    height: 15px;
    width: 15px;
    margin-bottom: 15px;
}

.social-media-div {
    writing-mode: vertical-rl;
    position: absolute;
    right: 20px;
    top: 49%;
    display: flex;
}

.banner-txt2 {
    font-size: 14px;
    font-weight: 500;
    writing-mode: vertical-rl;
    position: absolute;
    right: 20px;
    top: 20%;
}

.banner-txt2::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 60%;
    background-color: #ffffff;
    bottom: -65px;
    opacity: 0.6;
}

.section-padding {
    padding: 80px 0;
    z-index: 1;
}

.feature-div {
    padding: 30px 50px;
    border-radius: 100px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 0 2px 0px, rgb(60 64 67 / 9%) -1px 9px 9px 0px;
    background-color: #ffffff;
    margin-bottom: 80px;
}

.feature-div p {
    font-size: 16px;
    font-weight: 500;
    color: #171A1FFF;
}

.bg-img {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
    width: 100%;
    top: 18px;
}

.sec-head {
    font-size: 32px;
    font-weight: 600;
    color: #16191EFF;
}


/* Reviews */
.review-section {
    background-color: #FCFCFC;
    padding: 80px 40px;
}

.numbers-card {
    -webkit-box-shadow: 0 8px 30px 0 rgb(0 0 0 / 6%);
    -moz-box-shadow: 0 8px 30px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 8px 30px 0 rgb(0 0 0 / 6%);
}

.numbers-card .text1 {
    font-size: 24px;
    font-weight: 600;
}

.numbers-card p {
    color: #757783;
}

.numbers-card img {
    width: 60px;
}

.reviews-card .col-6 {
    height: 100%;
}

.reviews-card {
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 20px;
    border: 0;
    margin: 0 30px;
    margin-bottom: 50px;
    padding: 20px 15px
}

.reviews-card .user-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%;
}

.reviews-card p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}

.reviews-left {
    width: 50px;
}

.reviews-card h4 {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: #171A1FFF;
}

.reviews-card h5 {
    font-size: 14px;
    font-weight: 400;
    color: #DB762AFF;
    border-radius: 6px;
    border: 1px solid #DB762AFF;
    padding: 10px;
    margin: 0;
}

.review-quotes {
    position: absolute;
    right: 0;
}

.review-user {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100%;
}

.bg-img2 {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    z-index: -1;
    width: 100%;

}

.popular-div {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    background-color: #ffffff;
    border-radius: 15px;
    margin: 0 30px;
}

.university-logo {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 100%;
}

.university-review {
    position: absolute;
    right: 20px;
    top: 20px;
}

.university-ratings {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
}

.university-info {
    padding: 20px;
    text-align: center;
}

.university-review p {
    font-size: 14px;
    text-align: right;
    display: flex;
    justify-content: end;
    margin-bottom: 8px;
}

.university-info h4 {
    font-size: 18px;
    font-weight: 500;
    color: #171A1FFF;
    margin-bottom: 39px;
    line-height: 1.4;
    height: 25px !important;
}

.university-img::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    background-color: #000000;
    opacity: 0.5;
    height: 100%;
    width: 100%;

}

.img-overlay {
    position: relative;
    overflow: hidden;
}

/* .slick-slide{
    margin: 0 30px !important;
} */

.img-overlay img{
    height:220px !important;
    object-fit: cover !important;
}


.img-overlay::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #121E605C;
    border-radius: 15px;
}

.img-overlay img {
    display: block;
    max-width: 100%;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}


.review-slider .slick-track {
    margin-bottom: 10px;
    margin-top: 1%;
}

.review-slider .slick-prev {
    left: 88%;
}

.review-slider .slick-next {
    right: 3%;
}

.review-slider .slick-prev:before {
    content: "\f060";
    font-family: "Font Awesome 6 Free";
}

.review-slider .slick-next:before {
    content: "\f061";
    font-family: "Font Awesome 6 Free";
}

.review-slider .slick-arrow {
    margin: 0 auto;
    top: -73px;
    background-color: transparent;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    border: 1px solid #BDC1CAFF;
}


.slick-track {
    display: flex;
    align-items: stretch;
}

.slick-slide {
    height: auto;
    flex: 1;
}

.slick-prev:before,
.slick-next:before {
    color: #BDC1CAFF !important;
}


.social-icon-footer {
    list-style-type: none;
    margin: 0;
    display: flex;
    margin-top: 30px;
    padding-left: 10px;
}

footer .footer-logo {
    width: 200px;
}



.social-icon-footer svg {
    height: 30px;
    width: 30px;
    fill: #9095A1FF;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9095A1FF;
    margin-right: 15px;
}

.social-icon-footer svg:hover {
    fill: #ffffff;
    background-color: #19237f;
}

footer h3 {
    font-size: 20px;
    font-weight: 600;
    color: #171A1FFF;
}

.footer-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 20px;
}

.footer-list li a {
    font-size: 14px;
    line-height: 32px;
    font-weight: 400;
    color: #6B6D72FF;
}

/* .footer-list li a:hover {
    color: #DB762AFF;
} */

.bottom-footer p {
    font-size: 14px;
    font-weight: 400;
    color: #9095A1FF;
    margin: 0;
}

.bottom-footer a {
    color: #9095A1FF;
}

.feature-img {
    background-color: #FCECD6FF;
    padding: 8px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-img img {
    height: 25px;
    width: 25px;
}


.up-right {
    width: 15px;
    height: 15px;
    margin-left: 10px;
}

.university-info p a {
    display: flex;
    justify-content: center;
    align-items: center;
}


.breadcrumb li {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #323743FF
}

.breadcrumb a {
    color: #19237f;
}

.inner-banner {
    /* background-image: url(../images/university/asu-campus-banner.jpeg); */
    height: 350px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    z-index: -1;
}

.inner-banner::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.university-name {
    position: absolute;
    z-index: 1;
    bottom: -40px;
    left: 40px;
}

.university-name h1 {
    font-size: 30px;
    font-weight: 600;
}

.university-img-inner {
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #BDC1CAFF;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.university-img-inner img {
    width: 150px;
    max-width: 100%;
}

.z-index0 {
    z-index: 0;
}

.university-rank {
    position: absolute;
    right: 0;
    bottom: 0;
    right: 40px;
    z-index: 1;

}

.university-rank h5 {
    font-size: 16px;
    font-weight: 600;
    color: #323743FF;
    text-align: right;
    margin: 0;
}

.value {
    font-size: 40px;
    font-weight: 500;
    color: #14CE93FF;
    padding-top: 10px;
}

.number-text {
    font-size: 16px;
    font-weight: 500;
    color: #323743FF;
    padding-bottom: 15px;
}


.university-ratio::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #DEE1E6FF;
}

.university-ratio:first-child::after {

    content: none;

}


.uni-contact-details {
    background-color: #F5F7F9FF;
    border-radius: 12px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.uni-contact-details p,
.uni-contact-details a {
    font-size: 16px;
    font-weight: 400;
    color: #323743FF;
}

.uni-contact-details i {
    color: #3342B7FF;
}




.card-2 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    width: 100%;
    display: table;
    overflow: hidden;
    border: 0;
}

.card-2 .card-heading {
    /* background: url("../images/form-img.jpg") top left/cover no-repeat;
    width: 50%;
    display: table-cell; */
}

.card-2 .card-body {
    display: table-cell;
    padding: 60px;
    border-radius: 12px !important;
}

.review-form h2 {
    font-size: 30px;
    font-weight: 600;
    color: #171A1FFF;
    margin-bottom: 20px;
}


.review-form input,
.review-form textarea {
    background-color: #F2F4FDFF;
    border: 0;
}

.review-form label {
    font-size: 16px;
    color: #6B6D72FF;
    font-weight: 600;
}

.btn-primary {
    background-color: #4052DBFF;
    border-radius: 6px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-weight: 500;
}

.btn-primary:hover {
    background-color: transparent;
    border: 1px solid #4052DBFF;
    color: #4052DBFF;
}

.review-form .form-control:focus {
    background-color: #F2F4FDFF;
    border: 0;
}


/* Login */

.login-form {
    padding: 40px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.login-form h1 {
    font-size: 30px;
    font-weight: 700;
    color: #171A1FFF;
    text-align: center;
    margin-bottom: 30px;
}

.login-form input,
.login-form textarea {
    background-color: #F2F4FDFF;
    border: 0;
}

.login-form label {
    font-size: 16px;
    font-weight: 600;
    color: #171A1FFF;
    margin-bottom: 10px;
}

.login-form .form-control:focus {
    background-color: #F2F4FDFF;
    border: 0;
}

.login-form input,
.login-form .btn {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line-text {
    text-align: center;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    color: #565D6DFF;
    margin-top: 30px;
}

.line-text span {
    display: inline-block;
    position: relative;
    padding: 0 10px;
}

.line-text span:before,
.line-text span:after {
    content: '';
    display: block;
    position: absolute;
    width: 999px;
    border: 1px solid #DEE1E6FF;
    height: 1px;
    top: 50%;
    /* margin-top: -3px; */
}

.line-text span:before {
    right: 100%;
}

.line-text span:after {
    left: 100%;
}

.linkedin-login {
    height: auto !important;
    background-color: transparent;
    border-radius: 100px;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #323743FF;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #335CA6FF;
}

.linkedin-login:hover {
    background-color: #335CA6FF;
    color: #ffffff;
    border: 1px solid #335CA6FF;
}

.linkedin-login img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}


.bg-color-orange {
    background-color: #DB762AFF;
    width: 100%;
    height: 4px;
}

.bg-color-blue {
    background-color: #19237FFF;
    width: 100%;
    height: 4px;
    margin: 0 20px
}

.bg-color-green {
    background-color: #23AF23FF;
    width: 100%;
    height: 4px;

}

.digit-group input {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #000;
    margin: 0 8px;
    border: 1px solid #121E60FF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.digit-group .btn {
    padding: 0 20px;
    font-size: 14px;
    height: 33px;
    margin-left: 20px;

}

.digit-group {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


.login-form input::placeholder {
    color: #323743FF !important;
}


.signup-form input::placeholder {
    color: #BDC1CAFF !important;
}

.form-check-input:checked {
    background-color: #131e60;
    border-color: #131e60;
}

.form-check-input {
    height: 15px !important;
    width: 15px !important;
}

.form-check-label {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #171A1FFF !important;
}

.login-form .input-group-text {
    background-color: #f2f4fd;
    border: 0;
}

.form-check {
    display: flex;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem;
}


.login-form .input-group-text {
    display: flex;
    justify-content: center;
    padding-right: 24px;
    cursor: pointer;
}


.rating-star {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.rating-star i {
    color: #dee1e6;
    margin-right: 8px;
}

.rating-star .checked{
    color: #f3c640 !important;
}


.rating-star svg {
    margin-right: 10px;
    fill: #f3c640;
    width: 20px;
    height: 20px;
}

.university-review {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.university-review p {
    margin-bottom: 0;
}


.rating-star2 i {
    margin-right: 3px;
    font-size: 10px;
}



@media (max-width: 768px) {
    .card-2 {
        display: block;
    }

    .card-2 .card-heading {
        width: 100%;
        display: block;
        padding-top: 300px;
        background-position: left center;
    }

    .card-2 .card-body {
        display: block;
        padding: 60px 50px;
    }

    body {
        padding-top: 0 !important;
    }

    .home-banner h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .h1-small {
        font-size: 22px;
    }


    body {
        padding-top: 0 !important;
    }

    .section-padding {
        padding: 50px 0;
    }

    p {
        font-size: 14px !important;
        line-height: 1.4;
    }
}




@media (max-width:1366px) {
    .banner-form .btn {
        padding: 8px 45px;
    }
}

@media (max-width:1280px) {
    .banner-form .btn {
        padding: 8px 40px;
    }
}

@media(max-width:576px) {
    .mobile-mt-0 {
        margin-top: 0 !important;
    }


    .banner-txt,
    .banner-txt2,
    .social-media-div {
        display: none;
    }

    .home-banner h1 {
        font-size: 20px;
    }

    .h1-small {
        font-size: 16px;
    }

    .feature-div {
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 50px;
        display: block !important;
    }

    .feature-div .d-flex {
        margin-bottom: 20px;
    }

    .feature-img img {
        height: 20px;
        width: 20px;
    }


    .sec-head {
        font-size: 22px;
        margin-bottom: 20px !important;
    }

    .reviews-card h5 {
        font-size: 12px;
        padding: 5px;
    }

    .reviews-card h4 {
        font-size: 16px;
    }

    .reviews-card .user-img {
        width: 50px;
        height: 50px;
    }

    .review-quotes img {
        width: 40px;
        height: 40px;
    }

    .review-quotes {
        right: -20px;
    }

    .review-slider .slick-arrow {
        width: 30px;
        height: 30px;
        top: -38px;
    }

    .review-slider .slick-prev {
        left: 78%;
    }

    .social-icon-footer {
        margin-bottom: 30px;
        justify-content: center;
    }

    .bottom-footer p {
        margin-bottom: 15px;
    }

    .home-banner {
        padding: 60px 20px;
        height: auto;
    }

    .banner-form {
        margin-top: 20px;
        display: block;
        width: auto;
    }


    .banner-form .input-group {
        margin-bottom: 10px;
        margin-right: 0 !important;
    }

    .mobile-m {
        margin: 20px 10px !important;
    }

    footer .footer-logo {
        width: 150px;
        display: block;
        margin: 0 auto;
    }

    footer h3 {
        font-size: 18px;
        margin-top: 25px;
        text-align: center;
    }

    .footer-list {
        text-align: center;
    }

    .footer-list {
        margin-top: 10px;
    }

    .university-name {
        bottom: auto;
        left: 20px;
        right: 20px;
        margin: 0 auto;
        top: 20px;
    }

    .university-img-inner img {
        width: 80px;
    }

    .university-name h1 {
        font-size: 18px;
        font-weight: 600;
    }

    .university-rank {
        right: 20px;
        left: 20px;
        margin: 0 auto;
    }

    .inner-banner {
        height: 400px;
    }

    .number-text {
        font-size: 12px;
        padding-top: 10px;
    }

    .value {
        font-size: 18px;
    }

    .uni-contact-details {
        margin-top: 30px;
    }

    .card-2 .card-heading {
        display: none;
    }

    .card-2 .card-body {
        padding: 40px;
    }

    .login-form label {
        font-size: 14px;
    }
}