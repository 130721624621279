/* Scoped CSS for ReferralPage component */

/* Global Styles */
.container {
  max-width: 600px;
  margin: 50px auto;
  background-color: white;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  text-align: center;
}

p {
  text-align: center;
  color: #777;
  font-size: 1rem;
  margin-bottom: 20px;
}

/* Referral Link Section */
.referralLinkSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.referralInput {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.copyButton {
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Social Share Icons */
.socialIcons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.socialIcons a {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.5rem;
  color: #333;
}

.socialIcons a:hover {
  color: #000;
}

/* Referral Count */
.referralCount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.countBox {
  text-align: center;
  padding: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 130px;
}

/* Leaderboard Section */
.leaderboard {
  margin-top: 20px;
}

.leaderboard h2 {
  font-size: 1.3rem;
  text-align: left;
  margin-bottom: 10px;
}

.leaderboard ol {
  padding-left: 20px;
}

.leaderboard ol li {
  font-size: 1rem;
  margin-bottom: 8px;
}

.leaderboardNote {
  text-align: center;
  color: #777;
}

/* Footer */
.footer {
  text-align: center;
  margin-top: 40px;
  font-size: 0.9rem;
  color: #777;
}
